import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Rating from "@mui/lab/Rating";

import FavoriteIcon from "@mui/icons-material/Favorite";

const useStyles = makeStyles((theme) => ({
  tAlign: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  mTop: {
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      marginTop: "12px",
    },
  },
}));

function TestiminialsCards({ img, name, caption, text }) {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid
        item
        container
        md={12}
        xs={12}
        sm={12}
        alignItems="center"
        style={{
          justifyContent: "center",
          margin: "24px",
          backgroundColor: "white",
          borderRadius: "16px",
          padding: "24px",
        }}
      >
        <Grid item md={2} xs={2} sm={2} className={classes.tAlign}>
          <img
            src={img}
            alt="logo"
            style={{
              width: "100%",
              borderRadius: "200px",
              maxWidth: "80px",
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
            }}
          />
        </Grid>

        <Grid
          item
          md={6}
          xs={10}
          sm={10}
          style={{ paddingLeft: "24px", textAlign: "left" }}
        >
          <Typography variant="h6">
            <b>{name}</b>
          </Typography>

          <Typography variant="body2" style={{ color: "#949292" }}>
            <b>{caption}</b>
          </Typography>
        </Grid>

        <Grid item md={4} xs={12} sm={12} className={classes.mTop}>
          <Typography variant="body1" color="initial">
            <b> 5.0/5.0</b>
          </Typography>

          <Rating
            name="read-only"
            value={5}
            readOnly
            icon={<FavoriteIcon fontSize="medium" />}
          />
        </Grid>

        <Grid
          item
          container
          md={12}
          xs={12}
          sm={12}
          style={{ marginTop: "16px" }}
        >
          <Typography variant="body1" color="initial" align="justify">
            "{text}"
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TestiminialsCards;
