import { Container, Grid, Typography } from "@mui/material";
import Alert from "@mui/lab/Alert";

import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { db } from "../util/config";
import { Context } from "../util/Provider";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import Title from "../components/Title";

export default function SingleBlog(props) {
  const [loading, setLoading] = useState(true);

  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    try {
      const data = await db
        .collection("blogs")
        .doc(props.match.params.id)
        .get();

      setBlogs(data.data());
    } catch (e) {
      setBlogs(null);
    }
  };

  useEffect(() => {
    (async () => {
      const ad = await getBlogs();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Appbar />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="md"
          style={{
            marginTop: "12px",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          {blogs ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "24px" }}
                >
                  <Typography variant="h4" color="initial">
                    <b>{blogs.title}</b>
                  </Typography>

                  <Typography variant="overline" color="initial">
                    <b>{blogs.createdDate}</b>
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "24px" }}
                >
                  <img
                    src={blogs.files[0]}
                    alt="img"
                    style={{ width: "100%", borderRadius: "16px" }}
                  />
                </Grid>

                <Grid item md={12} xs={12} sm={12}>
                  <Typography
                    variant="body1"
                    color="initial"
                    style={{ whiteSpace: "pre-line", marginBottom: "12px" }}
                  >
                    {blogs.description}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12}>
              <Typography variant="body1" color="initial">
                No classes available
              </Typography>
            </Grid>
          )}
        </Container>
      )}
      <Title name="" height="30px" />

      <Footer />
    </>
  );
}
