import { Button, Container, Grid, Divider, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";

import { db } from "../util/config";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import Title from "../components/Title";

export default function Blogs() {
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    try {
      const allClasses = await db.collection("blogs").get();

      let allBlogs = [];

      allClasses.docs.map((doc) => {
        allBlogs.push(doc.data());
      });

      setBlogs(allBlogs);
    } catch (e) {
      setBlogs([]);
    }
  };

  useEffect(() => {
    (async () => {
      const ad = await getBlogs();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Appbar />
      <Title name="Blogs" height="120px" />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="md"
          style={{
            marginTop: "12px",
            padding: "24px",
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            style={{ marginBottom: "48px" }}
          >
            <b>
              We share the latest news and updates in the world of Information
              Technology here. So, keep an eye out for the intriguing
              developments in Technology and see for yourself how they could
              alter your knowledge of IT and if they open any new career paths
              to try out after school.
            </b>
          </Typography>

          {blogs.length > 0 ? (
            <>
              {blogs.map((blog) => {
                return (
                  <Grid
                    container
                    direction="row"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Grid
                      item
                      md={4}
                      xs={12}
                      sm={12}
                      style={{ padding: "12px" }}
                    >
                      <img
                        src={blog.files[0]}
                        alt="img"
                        style={{ width: "100%", borderRadius: "16px" }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      xs={12}
                      sm={12}
                      style={{ padding: "12px" }}
                    >
                      <Typography variant="h5" color="initial">
                        <b>{blog.title}</b>
                      </Typography>

                      <Typography variant="overline" color="initial">
                        <b>{blog.createdDate}</b>
                      </Typography>

                      <Typography
                        variant="body1"
                        color="initial"
                        style={{ whiteSpace: "normal", marginBottom: "12px" }}
                      >
                        {blog.description.slice(0, 250) + "..."}
                      </Typography>

                      <NavLink to={`/blog/${blog.blogID}`}>
                        <Typography variant="overline" color="initial">
                          <b>Read More ...</b>
                        </Typography>
                      </NavLink>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      xs={12}
                      sm={12}
                      style={{ marginBottom: "24px", marginTop: "12px" }}
                    >
                      <Divider />
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12}>
              <Typography variant="body1" color="initial">
                No Blogs available
              </Typography>
            </Grid>
          )}
        </Container>
      )}
      <Title name="" height="30px" />

      <Footer />
    </>
  );
}
