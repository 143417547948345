import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { Context } from "./Provider";

const AuthRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useContext(Context);

  return (
    <Route
      render={(props) =>
        currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }
      {...rest}
    />
  );
};

export default AuthRoute;
