import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CollectionsIcon from "@mui/icons-material/Collections";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArticleIcon from "@mui/icons-material/Article";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Context } from "../util/Provider";
import { app } from "../util/config";
import { ListItemIcon, ListItemText } from "@mui/material";

import logo from "../assets/images/logo.png";

import StudyGuidesDropdown from "../components/StudyGuidesDropdown";
import ClassesDropdown from "../components/ClassesDropdown";
import MobileStudyGuidesDropdown from "../components/MobileStudyGuidesDropdown";
import MobileClassesDropdown from "../components/MobileClassesDropdown";

const Appbar = () => {
  const { currentUser } = useContext(Context);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <NavLink to="/" style={{ color: "#000000" }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  maxWidth: "60px",
                  borderRadius: "8px",
                  marginTop: "6px",
                  marginBottom: "-3px",
                }}
              />
            </NavLink>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ fontSize: "36px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <NavLink to="/" style={{ color: "#000000" }}>
                <MenuItem>
                  <ListItemIcon>
                    <HomeIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography textAlign="left">
                      <b>Home</b>
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </NavLink>

              <MobileStudyGuidesDropdown />

              <NavLink to="/gallery" style={{ color: "#000000" }}>
                <MenuItem>
                  <ListItemIcon>
                    <CollectionsIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography textAlign="left">
                      <b>Gallery</b>
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </NavLink>

              <MobileClassesDropdown />

              <NavLink to="/blogs" style={{ color: "#000000" }}>
                <MenuItem>
                  <ListItemIcon>
                    <ArticleIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography textAlign="left">
                      <b>Blogs</b>
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </NavLink>

              <NavLink to="/about" style={{ color: "#000000" }}>
                <MenuItem>
                  <ListItemIcon>
                    <InfoIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography textAlign="left">
                      <b>About</b>
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </NavLink>
            </Menu>
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <NavLink to="/" style={{ color: "#000000" }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  maxWidth: "130px",
                  borderRadius: "8px",
                  marginBottom: "12px",
                  marginTop: "12px",
                }}
              />
            </NavLink>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <NavLink to="/" style={{ color: "#000000", marginRight: "8px" }}>
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                <HomeIcon
                  fontSize="small"
                  style={{ marginBottom: "-5px", marginRight: "6px" }}
                />
                <b>Home</b>
              </Button>
            </NavLink>

            <StudyGuidesDropdown />

            <NavLink
              to="/gallery"
              style={{ color: "#000000", marginRight: "8px" }}
            >
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                <CollectionsIcon
                  fontSize="small"
                  style={{ marginBottom: "-5px", marginRight: "6px" }}
                />
                <b>Gallery</b>
              </Button>
            </NavLink>

            <ClassesDropdown />

            <NavLink
              to="/blogs"
              style={{ color: "#000000", marginRight: "8px" }}
            >
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                <ArticleIcon
                  fontSize="small"
                  style={{ marginBottom: "-5px", marginRight: "6px" }}
                />
                <b>Blogs</b>
              </Button>
            </NavLink>

            <NavLink
              to="/about"
              style={{ color: "#000000", marginRight: "8px" }}
            >
              <Button sx={{ my: 2, color: "white", display: "block" }}>
                <InfoIcon
                  fontSize="small"
                  style={{ marginBottom: "-5px", marginRight: "6px" }}
                />
                <b>About </b>
              </Button>
            </NavLink>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {currentUser ? (
              <>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src={currentUser.profile} />
                </IconButton>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <NavLink to="/all-classes" style={{ color: "#000000" }}>
                    <MenuItem>
                      <ListItemIcon>
                        <SchoolIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography textAlign="let">
                          <b>All Classes</b>
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </NavLink>

                  <NavLink to="/my-classes" style={{ color: "#000000" }}>
                    <MenuItem>
                      <ListItemIcon>
                        <SchoolIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography textAlign="left">
                          <b>My Classes</b>
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </NavLink>

                  <NavLink to="/results" style={{ color: "#000000" }}>
                    <MenuItem>
                      <ListItemIcon>
                        <FormatListBulletedIcon
                          fontSize="small"
                          color="primary"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography textAlign="left">
                          <b>Exam Results</b>
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </NavLink>

                  <NavLink to="/profile" style={{ color: "#000000" }}>
                    <MenuItem>
                      <ListItemIcon>
                        <PersonIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography textAlign="left">
                          <b>Profile</b>
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </NavLink>

                  <MenuItem
                    onClick={() =>
                      app
                        .auth()
                        .signOut()
                        .then(() => {
                          window.location.reload();
                        })
                        .catch((err) => console.log(err))
                    }
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography textAlign="left">
                        <b>Logout</b>
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                {isDesktop ? (
                  <>
                    <NavLink to="/login">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          border: "1px solid #ffffff",
                          marginRight: "8px",
                        }}
                      >
                        <b>Login</b>
                      </Button>
                    </NavLink>

                    <NavLink to="/register">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ border: "1px solid #ffffff" }}
                      >
                        <b>Register</b>
                      </Button>
                    </NavLink>
                  </>
                ) : (
                  <>
                    {" "}
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0 }}
                      size="large"
                    >
                      <AccountCircleIcon
                        size="large"
                        style={{ color: "white", fontSize: "48px" }}
                      />
                    </IconButton>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <NavLink to="/login" style={{ color: "#000000" }}>
                        <MenuItem>
                          <ListItemIcon>
                            <LoginIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography textAlign="let">
                              <b>Login</b>
                            </Typography>
                          </ListItemText>
                        </MenuItem>
                      </NavLink>

                      <NavLink to="/register" style={{ color: "#000000" }}>
                        <MenuItem>
                          <ListItemIcon>
                            <HowToRegIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography textAlign="left">
                              <b>Register</b>
                            </Typography>
                          </ListItemText>
                        </MenuItem>
                      </NavLink>
                    </Menu>
                  </>
                )}
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Appbar;
