import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Alert from "@mui/lab/Alert";

import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { db } from "../util/config";
import { Context } from "../util/Provider";
import Footer from "../components/Footer";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import ConfirmBox from "../components/ConfirmBox";
import Snack from "../components/Snack";
import Title from "../components/Title";

export default function Profile() {
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState(null);

  const { currentUser } = useContext(Context);

  const getProfile = async () => {
    try {
      let pp = await db.collection("students").doc(currentUser.studentID).get();

      setProfile(pp.data());
    } catch (e) {
      setProfile(null);
    }
  };

  useEffect(() => {
    (async () => {
      const ad = await getProfile();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Appbar />
      <Title name="Profile" height="120px" />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="md"
          style={{
            marginTop: "12px",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          {profile ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                  boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
                  padding: "24px",
                }}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "24px" }}
                >
                  <Typography variant="h6" color="initial">
                    <b>Student ID</b>
                  </Typography>

                  <Typography variant="body1" color="initial">
                    {profile.studentID}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "24px" }}
                >
                  <Typography variant="h6" color="initial">
                    <b>Name</b>
                  </Typography>

                  <Typography variant="body1" color="initial">
                    {profile.name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "24px" }}
                >
                  <Typography variant="h6" color="initial">
                    <b>School</b>
                  </Typography>

                  <Typography variant="body1" color="initial">
                    {profile.school}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "24px" }}
                >
                  <Typography variant="h6" color="initial">
                    <b>Mobile</b>
                  </Typography>

                  <Typography variant="body1" color="initial">
                    {profile.mobile}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12}>
              <Typography variant="body1" color="initial">
                No details available
              </Typography>
            </Grid>
          )}
        </Container>
      )}
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
