import { Container, Grid, Divider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import React, { useState } from "react";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import Title from "../components/Title";

import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gal1 from "../assets/images/gal1.jpg";
import gal2 from "../assets/images/gal2.jpg";
import gal3 from "../assets/images/gal3.jpg";
import gal4 from "../assets/images/gal4.jpg";
import gal5 from "../assets/images/gal5.JPG";
import gal6 from "../assets/images/gal6.JPG";
import gal7 from "../assets/images/gal7.JPG";
import gal8 from "../assets/images/gal8.JPG";
import gal9 from "../assets/images/gal9.JPG";
import gal10 from "../assets/images/gal10.JPG";

export default function Gallery() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 5000,
    autoplay: true,
    cssEase: "linear",
  };

  return (
    <>
      <Appbar />
      <Title name="Gallery" height="120px" />

      <Container
        maxWidth="lg"
        style={{
          marginTop: "12px",
          padding: "24px",
        }}
      >
        <Typography variant="h6" color="primary">
          <b>
            {" "}
            Wondering what it may look like to have a seat at the best IT class
            in Sri Lanka? Have a look for yourself.
            <br />
            <br />
            The gallery hopes to let all students and parents into the community
            and the experience a student regularly enjoys at our IT class
            through images and other aids. We hope that these resources help you
            have a better understanding and address any inquiries you may have
            about the environment, the learning process, and other features of
            our IT class.
            <br />
            <br />
            If you’re interested in getting to know more about our IT class,
            contact us today!
          </b>
        </Typography>
      </Container>

      <Container
        maxWidth="lg"
        style={{
          marginTop: "12px",
          padding: "0px",
        }}
      >
        <div>
          {isMobile ? (
            <Slider {...settings}>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal1}
                  alt="gal1"
                />
              </div>

              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal2}
                  alt="gal2"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal3}
                  alt="gal3"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal4}
                  alt="gal4"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal5}
                  alt="gal5"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal6}
                  alt="gal6"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal7}
                  alt="gal7"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal8}
                  alt="gal8"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal9}
                  alt="gal9"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal9}
                  alt="gal9"
                />
              </div>
            </Slider>
          ) : (
            <Slider {...settings}>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal1}
                  alt="gal1"
                />
              </div>

              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal2}
                  alt="gal2"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal3}
                  alt="gal3"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal4}
                  alt="gal4"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal5}
                  alt="gal5"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal6}
                  alt="gal6"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal7}
                  alt="gal7"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal8}
                  alt="gal8"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal9}
                  alt="gal9"
                />
              </div>
              <div>
                <img
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={gal10}
                  alt="gal10"
                />
              </div>
            </Slider>
          )}
        </div>
      </Container>
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
