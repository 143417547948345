import {
  Button,
  Container,
  Divider,
  Grid,
  Alert,
  Typography,
  AlertTitle,
} from "@mui/material";

import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { db } from "../util/config";
import { Context } from "../util/Provider";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import AccorPdf2 from "../components/AccorPdf2 ";
import Accor from "../components/Accor";
import Footer from "../components/Footer";
import Title from "../components/Title";

const fileList = [
  {
    name: "Data and Information Grade 10 Lesson 01",
    file: "/Data and Information Grade 10 Lesson 01.pdf",
  },
];

export default function OLGuides(props) {
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [modules, setModules] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [papers, setPapers] = useState([]);

  const { currentUser } = useContext(Context);

  const getModules = async () => {
    try {
      const modules = await db
        .collection("studyguides")
        .where("classType.value", "==", "OL")
        .where("materialTypes.value", "==", "Study Note")
        .get();

      let modulesList = [];

      modules.docs.map((doc) => {
        modulesList.push(doc.data());
      });

      setModules(modulesList);
      setError(null);
    } catch (e) {
      setModules([]);
    }
  };

  const getSyllabus = async () => {
    try {
      const modules = await db
        .collection("studyguides")
        .where("classType.value", "==", "OL")
        .where("materialTypes.value", "==", "Syllabus")
        .get();

      let modulesList = [];

      modules.docs.map((doc) => {
        modulesList.push(doc.data());
      });

      setSyllabus(modulesList);
      setError(null);
    } catch (e) {
      setSyllabus([]);
    }
  };

  const getPapers = async () => {
    try {
      const modules = await db
        .collection("studyguides")
        .where("classType.value", "==", "OL")
        .where("materialTypes.value", "==", "Paper")
        .get();

      let modulesList = [];

      modules.docs.map((doc) => {
        modulesList.push(doc.data());
      });

      setPapers(modulesList);
      setError(null);
    } catch (e) {
      setPapers([]);
    }
  };

  useEffect(() => {
    (async () => {
      const ad = await getModules();
      const sy = await getSyllabus();
      const pa = await getPapers();

      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Appbar />

      <Title name="OL - Study Guides" height="120px" />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            marginTop: "12px",
            marginBottom: "24px",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          <Typography
            align="left"
            variant="h5"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Study Notes</b>
          </Typography>

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              textAlign: "left",
            }}
          >
            <Grid item md={12} xs={12} sm={12}>
              <AccorPdf2 modules={fileList} />
            </Grid>
          </Grid>

          {/* {modules.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={modules} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Study Notes available
              </Typography>
            </Grid>
          )} */}

          <Typography
            align="left"
            variant="h5"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Syllabus</b>
          </Typography>

          {syllabus.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                {error ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="error">
                      <b>{error}</b>
                    </Alert>
                  </Grid>
                ) : null}

                {success ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="success">
                      <b>{success}</b>
                    </Alert>
                  </Grid>
                ) : null}

                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={syllabus} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Syllabus available
              </Typography>
            </Grid>
          )}

          <Typography
            align="left"
            variant="h5"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Papers</b>
          </Typography>

          {papers.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                {error ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="error">
                      <b>{error}</b>
                    </Alert>
                  </Grid>
                ) : null}

                {success ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="success">
                      <b>{success}</b>
                    </Alert>
                  </Grid>
                ) : null}

                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={papers} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Papers available
              </Typography>
            </Grid>
          )}
        </Container>
      )}

      <Title name="" height="30px" />

      <Footer />
    </>
  );
}
