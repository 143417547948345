import {
  Button,
  Container,
  Divider,
  Grid,
  Alert,
  Typography,
  AlertTitle,
} from "@mui/material";

import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { db } from "../util/config";
import { Context } from "../util/Provider";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import Accor from "../components/Accor";
import Footer from "../components/Footer";
import Title from "../components/Title";

export default function Modules(props) {
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [modules, setModules] = useState([]);
  const [exams, setExams] = useState([]);
  const [term, setTerm] = useState([]);
  const [spot, setSpot] = useState([]);
  const [unit, setUnit] = useState([]);
  const [notices, setNotices] = useState([]);

  const { currentUser } = useContext(Context);

  const getModules = async () => {
    try {
      const allowed = await db
        .collection("enrolled-students")
        .where("classID", "==", props.match.params.id)
        .where("studentID", "==", currentUser.studentID)
        .get();

      if (allowed.docs.length > 0) {
        const modules = await db
          .collection("modules")
          .where("classID", "==", props.match.params.id)
          .orderBy("createdDate")
          .get();

        let modulesList = [];

        modules.docs.map((doc) => {
          modulesList.push(doc.data());
        });

        setModules(modulesList);
        setError(null);
      } else {
        setModules([]);
        setError("You are not authorized to access this module.");
      }
    } catch (e) {
      setModules([]);
    }
  };

  const getExams = async () => {
    try {
      const allowed = await db
        .collection("enrolled-students")
        .where("classID", "==", props.match.params.id)
        .where("studentID", "==", currentUser.studentID)
        .get();

      if (allowed.docs.length > 0) {
        const exams = await db
          .collection("exams")
          .where("classID", "==", props.match.params.id)
          .orderBy("createdDate")
          .get();

        let examsList = [];
        let spotList = [];
        let termList = [];
        let unitList = [];

        exams.docs.map((doc) => {
          if (doc.data().category === "Spot Test") {
            spotList.push(doc.data());
          } else if (doc.data().category === "Term Test") {
            termList.push(doc.data());
          } else {
            unitList.push(doc.data());
          }
          examsList.push(doc.data());
        });

        console.log(examsList);
        console.log(spotList);
        console.log(termList);
        console.log(unitList);
        setExams(examsList);
        setTerm(termList);
        setSpot(spotList);
        setUnit(unitList);

        setError(null);
      } else {
        setExams([]);
        setError("You are not authorized to access this module.");
      }
    } catch (e) {
      setExams([]);
    }
  };

  const getNotices = async () => {
    try {
      const allowed = await db
        .collection("enrolled-students")
        .where("classID", "==", props.match.params.id)
        .where("studentID", "==", currentUser.studentID)
        .get();

      if (allowed.docs.length > 0) {
        const exams = await db
          .collection("notices")
          .where("classID", "==", props.match.params.id)
          .orderBy("createdDate")
          .get();

        let noticeList = [];

        exams.docs.map((doc) => {
          noticeList.push(doc.data());
        });

        setNotices(noticeList);
        setError(null);
      } else {
        setNotices([]);
        setError("You are not authorized to access this module.");
      }
    } catch (e) {
      setNotices([]);
    }
  };

  useEffect(() => {
    (async () => {
      const ad = await getModules();
      const ex = await getExams();
      const no = await getNotices();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Appbar />
      <Title name={props.match.params.id} height="120px" />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            marginTop: "12px",
            marginBottom: "24px",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          {error ? (
            <Grid item md={12} xs={12} sm={12} style={{ marginBottom: "24px" }}>
              <Alert severity="error">
                <b>{error}</b>
              </Alert>
            </Grid>
          ) : null}

          {notices.length > 0 ? (
            <>
              <Typography
                align="left"
                variant="h5"
                color="initial"
                style={{ marginBottom: "24px", marginTop: "24px" }}
              >
                <b>Notices</b>
              </Typography>

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                {error ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="error">
                      <b>{error}</b>
                    </Alert>
                  </Grid>
                ) : null}

                {success ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="success">
                      <b>{success}</b>
                    </Alert>
                  </Grid>
                ) : null}

                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ marginBottom: "12px" }}
                >
                  {notices.map((notice) => {
                    return (
                      <Alert severity="info" style={{ marginBottom: "12px" }}>
                        <AlertTitle>{notice.title}</AlertTitle>
                        {notice.description}
                      </Alert>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          ) : null}

          <Typography
            align="left"
            variant="h5"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Modules</b>
          </Typography>

          {modules.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                {error ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="error">
                      <b>{error}</b>
                    </Alert>
                  </Grid>
                ) : null}

                {success ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="success">
                      <b>{success}</b>
                    </Alert>
                  </Grid>
                ) : null}

                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={modules} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Modules available
              </Typography>
            </Grid>
          )}

          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <Divider />
          </Grid>

          <Typography
            align="left"
            variant="h5"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Exams</b>
          </Typography>

          <Typography
            align="left"
            variant="h6"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Term Test</b>
          </Typography>

          {term.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={term} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Term Tests available
              </Typography>
            </Grid>
          )}

          <Typography
            align="left"
            variant="h6"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Spot Test</b>
          </Typography>

          {spot.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={spot} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Spot Tests available
              </Typography>
            </Grid>
          )}

          <Typography
            align="left"
            variant="h6"
            color="initial"
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <b>Unit Test</b>
          </Typography>

          {unit.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={unit} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Unit Tests available
              </Typography>
            </Grid>
          )}
          {/* 
          {exams.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
               

                <Grid item md={12} xs={12} sm={12}>
                  <Accor modules={exams} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12} style={{ marginTop: "24px" }}>
              <Typography variant="body1" color="initial">
                No Exams available
              </Typography>
            </Grid>
          )} */}
        </Container>
      )}
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
