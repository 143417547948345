import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxa_l7OVnOqtOWNReaX2cJG8dttEc3D1M",
  authDomain: "apeekamaitpanthiya-4749b.firebaseapp.com",
  projectId: "apeekamaitpanthiya-4749b",
  storageBucket: "apeekamaitpanthiya-4749b.appspot.com",
  messagingSenderId: "353730832705",
  appId: "1:353730832705:web:f6810eb8b0b16254ed19c0",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

//db Firebase
const db = firebase.firestore();

//storageBucket
const bucket = firebase.storage();

// Create a root reference
const storageRef = firebase.storage().ref();

export { app, db, bucket, storageRef };
