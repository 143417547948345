import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography } from "@mui/material";

import Slider from "react-slick";

import TestiminialsCards from "./TestiminialsCards";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Rating from "@mui/lab/Rating";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import st1 from "../assets/images/st1.png";
import std2 from "../assets/images/std2.png";
import std4 from "../assets/images/std4.png";
import std3 from "../assets/images/std3.png";

const useStyles = makeStyles((theme) => ({
  ctr: {
    padding: "24px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  imgMb: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "24px",
    },
  },
  tAlign: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  mTop: {
    marginTop: "0px",
    [theme.breakpoints.down("md")]: {
      marginTop: "12px",
    },
  },
}));

function CommentSlider() {
  const classes = useStyles();

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 4000,
    autoplay: false,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.ctr}>
      <Slider {...settings}>
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid
            item
            container
            md={12}
            xs={12}
            sm={12}
            alignItems="center"
            style={{
              justifyContent: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "36px",
            }}
          >
            <Grid item md={2} xs={2} sm={2} className={classes.tAlign}>
              <img
                src={std3}
                alt="logo"
                style={{
                  width: "100%",
                  borderRadius: "200px",
                  maxWidth: "80px",
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
                }}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={10}
              sm={10}
              style={{ paddingLeft: "24px", textAlign: "left" }}
            >
              <Typography variant="h6">
                <b>Bodhana Janalath</b>
              </Typography>

              <Typography variant="body2" style={{ color: "#949292" }}>
                <b>Undergraduate</b>
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} sm={12} className={classes.mTop}>
              <Typography variant="body1" color="initial">
                <b> 5.0/5.0</b>
              </Typography>

              <Rating
                name="read-only"
                value={5}
                readOnly
                icon={<FavoriteIcon fontSize="medium" />}
              />
            </Grid>

            <Grid
              item
              container
              md={12}
              xs={12}
              sm={12}
              style={{ marginTop: "16px" }}
            >
              <Typography variant="body1" color="initial" align="justify">
                "As the student I recommended this ICT class for you. our sir
                contains various creative teaching skills that helps to enhance
                your knowledge about ICT subject. other than that, our sir will
                provide you colorful teaching aids which will be helpful to
                enhance your knowledge. the most important thing is this teacher
                has the massive talent to teach difficult subject matters during
                very short period time. this will save your valuable time. The
                journey with Jeewantha sir helps you to complete you’re A/L
                journey in successful manner with full of fun and enjoyment.
                <br />
                <br />
                Bodhana Janalath
                <br />
                Faculty of technology – University of Sri Jayewardenepura
                <br />
                (District 15 th - Bio systems technology – z score 2.0028 )
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" justify="center">
          <Grid
            item
            container
            md={12}
            xs={12}
            sm={12}
            alignItems="center"
            style={{
              justifyContent: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "36px",
            }}
          >
            <Grid item md={2} xs={2} sm={2} className={classes.tAlign}>
              <img
                src={std4}
                alt="logo"
                style={{
                  width: "100%",
                  borderRadius: "200px",
                  maxWidth: "80px",
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
                }}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={10}
              sm={10}
              style={{ paddingLeft: "24px", textAlign: "left" }}
            >
              <Typography variant="h6">
                <b>B.H.Manuri Hansini Danansuriya</b>
              </Typography>

              <Typography variant="body2" style={{ color: "#949292" }}>
                <b>Undergraduate</b>
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} sm={12} className={classes.mTop}>
              <Typography variant="body1" color="initial">
                <b> 5.0/5.0</b>
              </Typography>

              <Rating
                name="read-only"
                value={5}
                readOnly
                icon={<FavoriteIcon fontSize="medium" />}
              />
            </Grid>

            <Grid
              item
              container
              md={12}
              xs={12}
              sm={12}
              style={{ marginTop: "16px" }}
            >
              <Typography variant="body1" color="initial" align="justify">
                "I choose to do my A/L's with technology stream and in that I
                choose my ICT teacher as Jeewantha sir. He is a best ICT teacher
                that I have met and with his proper guidence and teaching way
                help me to achieve my targets. Sir used several teaching skills
                to give proper undestand about theories and the way sir teach
                helped us to undestand the hardest things easily. As well as if
                anyone come up with a question sir always be there to support us
                and sir always did his best to us. So, I highly recommended this
                ICT class to you all. Thank you.
                <br />
                <br />
                B.H.Manuri Hansini Danansuriya <br />
                Faculty of Technology <br />
                University of Sri Jayawardenepura
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" justify="center">
          <Grid
            item
            container
            md={12}
            xs={12}
            sm={12}
            alignItems="center"
            style={{
              justifyContent: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "36px",
            }}
          >
            <Grid item md={2} xs={2} sm={2} className={classes.tAlign}>
              <img
                src={st1}
                alt="logo"
                style={{
                  width: "100%",
                  borderRadius: "200px",
                  maxWidth: "80px",
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
                }}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={10}
              sm={10}
              style={{ paddingLeft: "24px", textAlign: "left" }}
            >
              <Typography variant="h6">
                <b>Chamath Viduranga</b>
              </Typography>

              <Typography variant="body2" style={{ color: "#949292" }}>
                <b>Undergraduate</b>
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} sm={12} className={classes.mTop}>
              <Typography variant="body1" color="initial">
                <b> 5.0/5.0</b>
              </Typography>

              <Rating
                name="read-only"
                value={5}
                readOnly
                icon={<FavoriteIcon fontSize="medium" />}
              />
            </Grid>

            <Grid
              item
              container
              md={12}
              xs={12}
              sm={12}
              style={{ marginTop: "16px" }}
            >
              <Typography variant="body1" color="initial" align="justify">
                "I'm Chamath Viduranga, an undergraduate student at the
                University of Sri Jayewardenepura's Faculty of Technology. I did
                ICT for my A/Ls. It was my first time. I was worried that I
                wouldn't be able to get a good grade in the subject. However,
                with Jeewantha sir's class and the manner in which he teaches,
                anyone can easily grasp the subject matter.
                <br />
                <br />
                So I was able to get a B in ICT, which helped me raise my Z
                score to 3rd in the Colombo District and 17th in the island
                ranking.
                <br />
                <br />
                If you want to learn ICT in a unique and effective way, this is
                the place to be."
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" justify="center">
          <Grid
            item
            container
            md={12}
            xs={12}
            sm={12}
            alignItems="center"
            style={{
              justifyContent: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "36px",
            }}
          >
            <Grid item md={2} xs={2} sm={2} className={classes.tAlign}>
              <img
                src={std2}
                alt="logo"
                style={{
                  width: "100%",
                  borderRadius: "200px",
                  maxWidth: "80px",
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
                }}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={10}
              sm={10}
              style={{ paddingLeft: "24px", textAlign: "left" }}
            >
              <Typography variant="h6">
                <b>Dileesha Boteju</b>
              </Typography>

              <Typography variant="body2" style={{ color: "#949292" }}>
                <b>Undergraduate</b>
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} sm={12} className={classes.mTop}>
              <Typography variant="body1" color="initial">
                <b> 5.0/5.0</b>
              </Typography>

              <Rating
                name="read-only"
                value={5}
                readOnly
                icon={<FavoriteIcon fontSize="medium" />}
              />
            </Grid>

            <Grid
              item
              container
              md={12}
              xs={12}
              sm={12}
              style={{ marginTop: "16px" }}
            >
              <Typography variant="body1" color="initial" align="justify">
                "This is Dileesha Boteju. I'm a third-year undergraduate in the
                Faculty of Technology University Of Sri Jayawardanepura. Right
                after completing my O/L exam, I choose to do ICT in my A/L as
                the main subject along with my other technology subjects. So
                I've been a student of jeevantha sir since grade 10. I continued
                it to my A levels as well. I highly recommend this ICT class for
                all O/L and A/L students who are willing to get higher results.
                I've got 2.1429 Z score in my A level exam thanks to this ICT
                class. As you know ICT subject can increase your Z score to a
                higher level. This ICT class really helped to achieve my target.
                Sir covered our syllabus earlier, then started to do the
                different MCQ papers and essay papers while recovering all
                theory parts. His friendly way of teaching, immense support and
                guidance helped me a lot to get higher results. I highly
                recommend this ICT class to you all.
                <br />
                <br />
                Thank you
                <br />
                Dileesha Boteju
                <br />
                Colombo District 12 (Biosystems Technology stream ) 2017 Advance
                Level
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Slider>
    </div>
  );
}

export default CommentSlider;
