import { Grid, Button } from "@mui/material";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import PDF from "/DBMS - 1.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Pdf({ pdf }) {
  console.log(pdf);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    console.log(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      style={{
        textAlign: "left",
      }}
    >
      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        className="controls"
        style={{ textAlign: "center" }}
      >
        <Button
          style={{ zIndex: "9999", padding: "12px" }}
          onClick={prevPage}
          disabled={pageNumber === 1}
          variant="contained"
          color="primary"
        >
          <b>Prev</b>
        </Button>

        <Button
          style={{ zIndex: "9999", padding: "12px" }}
          onClick={nextPage}
          disabled={pageNumber === numPages}
          variant="contained"
          color="primary"
        >
          <b>Next</b>
        </Button>
      </Grid>

      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        className="controls"
        style={{ textAlign: "center" }}
      >
        <p>
          {pageNumber} / {numPages}
        </p>
      </Grid>

      <Grid item md={12} xs={12} sm={12}>
        <Document
          file={pdf}
          style={{ width: "100%", height: "100%" }}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </Grid>
    </Grid>
  );
}
