import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import { db } from "../util/config";
import Footer from "../components/Footer";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import Title from "../components/Title";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Schedules() {
  const [guides, setSchedules] = useState([]);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getResults = async () => {
    setLoading(true);

    try {
      const data = await db.collection("schedules").orderBy("institute").get();
      let schedulesList = [];

      data.docs.map((doc) => {
        schedulesList.push(doc.data());
      });

      setSchedules(schedulesList);
      setLoading(false);
    } catch (err) {
      setSchedules([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getResults();
  }, []);

  return (
    <>
      <Appbar />
      <Title name="Class Schedules" height="120px" />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            marginTop: "12px",
            padding: "24px",
          }}
        >
          <Typography variant="h6" color="primary">
            <b>
              Have no fear of missing another IT class again! Check the schedule
              below and plan your week accordingly.
            </b>
          </Typography>

          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <Container maxWidth="md">
                <Grid container direction="row">
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    direction="row"
                    style={{ paddingTop: "48px" }}
                  >
                    {guides.length > 0 ? (
                      <>
                        <Grid item md={12} xs={12} sm={12}>
                          <TableContainer
                            component={Paper}
                            style={{
                              boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
                            }}
                          >
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">
                                    <Typography variant="h6" color="initial">
                                      <b>Institute</b>
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h6" color="initial">
                                      <b>Grade</b>
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h6" color="initial">
                                      <b>Year</b>
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h6" color="initial">
                                      <b>Day</b>
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="h6" color="initial">
                                      <b>Time</b>
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {guides.map((guide) => (
                                  <TableRow key={guide.scheduleID}>
                                    <TableCell component="th" scope="row">
                                      <b>{guide.institute}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <b>{guide.grade?.value}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <b>{guide.year?.value}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <b>{guide.day?.value}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <b>{guide.time}</b>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </>
                    ) : (
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography variant="body1" color="initial">
                          No Schedules Available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
          </Box>
        </Container>
      )}
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
