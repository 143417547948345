import { Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";
import logo from "../assets/images/logo.png";
import logo2 from "../assets/images/m1.png";
import logo3 from "../assets/images/logo1.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logobox: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  logoText: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  bodytext: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      marginBottom: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "8px",
    },
  },

  social: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  thirdImage: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "170px",
      marginLeft: "25px",
      marginBottom: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "170px",
      marginTop: "30px",
      marginBottom: "20px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="false">
        <Divider />
      </Container>

      <Container
        maxWidth="false"
        style={{
          backgroundColor: "white",
          paddingTop: "5px",
          paddingBottom: "25px",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <Grid
            container
            direction="row"
            justify="center"
            style={{ textAlign: "center" }}
          >
            <Grid item md={4} xs={12} sm={2} style={{ textAlign: "left" }}>
              <Typography
                variant="body2"
                color="initial"
                className={classes.bodytext}
                style={{
                  marginBottom: "12px",
                }}
              >
                <b>Useful Quick Links</b>
              </Typography>
              <NavLink to="/">
                <Typography
                  variant="body2"
                  color="initial"
                  className={classes.bodytext}
                >
                  Home
                </Typography>
              </NavLink>

              <NavLink to="/about">
                <Typography
                  variant="body2"
                  color="initial"
                  className={classes.bodytext}
                >
                  About us
                </Typography>
              </NavLink>

              <NavLink to="/gallery">
                <Typography
                  variant="body2"
                  color="initial"
                  className={classes.bodytext}
                >
                  Gallery
                </Typography>
              </NavLink>
            </Grid>

            <Grid item md={4} xs={12} sm={2} style={{ textAlign: "left" }}>
              <Typography
                variant="body2"
                color="initial"
                className={classes.bodytext}
                style={{
                  marginBottom: "12px",
                }}
              >
                <b>Useful Quick Links</b>
              </Typography>
              <NavLink to="/Schedules">
                <Typography
                  variant="body2"
                  color="initial"
                  className={classes.bodytext}
                >
                  Class Schedules
                </Typography>
              </NavLink>

              <NavLink to="/location">
                <Typography
                  variant="body2"
                  color="initial"
                  className={classes.bodytext}
                >
                  Class Locations
                </Typography>
              </NavLink>

              <NavLink to="/exam-results">
                <Typography
                  variant="body2"
                  color="initial"
                  className={classes.bodytext}
                >
                  Past Results
                </Typography>
              </NavLink>
            </Grid>

            <Grid item md={4} xs={12} sm={2} style={{ textAlign: "left" }}>
              <Typography
                variant="body2"
                color="initial"
                className={classes.bodytext}
                style={{
                  marginBottom: "12px",
                }}
              >
                <b>Contact US</b>
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                className={classes.bodytext}
              >
                Mr. Jeevantha Hewa Pathirana
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                className={classes.bodytext}
              >
                <LocalPhoneIcon
                  size="small"
                  style={{
                    marginBottom: "-4.5px",
                    fontSize: "18px",
                    marginRight: "5px",
                  }}
                />
                +94 77 720 8864
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                className={classes.bodytext}
              >
                <EmailIcon
                  size="small"
                  style={{
                    marginBottom: "-4.5px",
                    fontSize: "18px",
                    marginRight: "5px",
                  }}
                />
                sjeevantha@gmail.com
              </Typography>
              <Typography
                className={classes.social}
                style={{
                  marginTop: "12px",
                }}
              >
                <a
                  href="https://www.facebook.com/jeevanthas"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon
                    color="primary"
                    fontSize="large"
                    style={{
                      marginRight: "8px",
                    }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCMTnhDX_Xr54GdOo4xzUfbg?app=desktop"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon
                    color="primary"
                    fontSize="large"
                    style={{
                      marginRight: "8px",
                    }}
                  />
                </a>
                <a
                  href="https://instagram.com/jeevantha_hewapathirana?utm_medium=copy_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon
                    color="primary"
                    fontSize="large"
                    style={{
                      marginRight: "8px",
                    }}
                  />
                </a>
                <a
                  href="https://twitter.com/JeevanthaHPS?t=720NXWpuTIk-EIuMfUT2qA&s=09"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon
                    color="primary"
                    fontSize="large"
                    style={{
                      marginRight: "7px",
                    }}
                  />
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="false">
          <Divider />
        </Container>

        <Container
          maxWidth="lg"
          style={{
            marginTop: "5px",
            marginBottom: "-10px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item md={6} xs={12} sm={6} className={classes.logobox}>
              <img
                src={logo}
                alt="logo"
                style={{
                  maxWidth: "250px",
                  marginBottom: "30px",
                  marginRight: "20px",
                }}
              />
              <img
                src={logo2}
                alt="logo"
                style={{
                  maxWidth: "200px",
                  marginLeft: "25px",
                  marginRight: "20px",
                }}
              />
              <img src={logo3} alt="logo" className={classes.thirdImage} />
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Typography
                variant="body2"
                color="initial"
                className={classes.logoText}
              >
                © {new Date().getFullYear()} Ape Ekama IT Panthiya Sri Lanka.
                All rights reserved.
              </Typography>
              <Typography
                variant="body2"
                color="initial"
                className={classes.logoText}
              >
                Designed & Developed by{" "}
                <a
                  style={{
                    color: "#2562E9",
                  }}
                  href="https://codesec.global/"
                  target="_blank"
                >
                  CodeSec Global
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}
