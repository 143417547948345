import { Container, Grid, Divider, Typography } from "@mui/material";

import React from "react";

import Appbar from "../components/Appbar";
import Footer from "../components/Footer";
import Title from "../components/Title";

import l1 from "../assets/images/l1.PNG";
import l2 from "../assets/images/l2.PNG";
import l3 from "../assets/images/l3.PNG";

const iframe = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.083688853468!2d79.95663871525123!3d6.8805773208169105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae251a269d8d03f%3A0xc8433ebf04d0dba5!2sSadhana%20higher%20education%20center!5e0!3m2!1sen!2sus!4v1640369596829!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;
const iframe2 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.872879470879!2d79.95153651477285!3d6.905801095010071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2572de2ea978b%3A0xf8a9b83c5266cf5c!2sDaham%20Mawatha%20Higher%20Educational%20institute!5e0!3m2!1sen!2slk!4v1640369752588!5m2!1sen!2slk" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;
const iframe3 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.707196866435!2d79.86506771477293!3d6.925561194996115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2590e97c4ea61%3A0x2474c66250edd48d!2sYathra%20Institution!5e0!3m2!1sen!2slk!4v1640532841820!5m2!1sen!2slk" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;

export default function Location() {
  function createMarkup() {
    return { __html: iframe };
  }
  function createMarkup2() {
    return { __html: iframe2 };
  }
  function createMarkup3() {
    return { __html: iframe3 };
  }

  return (
    <>
      <Appbar />
      <Title name="Locations" height="120px" />

      <Container
        maxWidth="lg"
        style={{
          marginTop: "12px",
          padding: "24px",
        }}
      >
        <Typography variant="h6" color="primary">
          <b>
            With our IT tuition classes based all over the city, you do not need
            to worry about the commute. Pick the closest location to you and
            plan your trip conveniently.
            <br />
            <br />
            If you have more inquiries about our IT tuition classes or the
            location, contact us today!
          </b>
        </Typography>

        <Grid container style={{ marginTop: "24px" }}>
          <Grid item md={12} xs={12} sm={12} style={{ padding: "24px" }}>
            <Typography variant="h4" align="left" color="primary">
              Sadhana higher education center
            </Typography>
            <Typography variant="h6" align="left" color="primary">
              Hokandara Rd, Hokandara 11018, Sri Lanka
            </Typography>
            <Typography variant="h6" align="left" color="primary">
              0777980845
            </Typography>
          </Grid>

          <Grid item md={6} xs={12} sm={12} style={{ padding: "24px" }}>
            <div dangerouslySetInnerHTML={createMarkup()} />
          </Grid>

          <Grid item md={6} xs={12} sm={12} style={{ padding: "24px" }}>
            <img src={l1} alt="l1" style={{ width: "100%" }} />
          </Grid>

          <Grid item md={12} xs={12} sm={12} style={{ padding: "24px" }}>
            <Typography variant="h4" align="left" color="primary">
              Daham Mawatha Higher Educational institute
            </Typography>

            <Typography variant="h6" align="left" color="primary">
              0721705942{" "}
            </Typography>
          </Grid>

          <Grid item md={6} xs={12} sm={12} style={{ padding: "24px" }}>
            <div dangerouslySetInnerHTML={createMarkup2()} />
          </Grid>

          <Grid item md={6} xs={12} sm={12} style={{ padding: "24px" }}>
            <img src={l2} alt="l1" style={{ width: "100%" }} />
          </Grid>

          <Grid item md={12} xs={12} sm={12} style={{ padding: "24px" }}>
            <Typography variant="h4" align="left" color="primary">
              Yathra Institution
            </Typography>

            <Typography variant="h6" align="left" color="primary">
              593 Maradana Rd, Colombo 01000{" "}
            </Typography>
            <Typography variant="h6" align="left" color="primary">
              0714842160{" "}
            </Typography>
          </Grid>

          <Grid item md={6} xs={12} sm={12} style={{ padding: "24px" }}>
            <div dangerouslySetInnerHTML={createMarkup3()} />
          </Grid>

          <Grid item md={6} xs={12} sm={12} style={{ padding: "24px" }}>
            <img src={l3} alt="l1" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
