import React, { useEffect, useState } from "react";
import { app, db } from "./config";

import { CircularProgress } from "@mui/material";

export const Context = React.createContext();

export const Provider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [userdata, setUserData] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      if (user) {
        return db
          .collection("students")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists && doc.data() && doc.data().verified) {
              setCurrentUser(doc.data());
              setPending(false);
            } else {
              setPending(false);
              setCurrentUser(null);
            }
          });
      } else {
        setPending(false);
        setCurrentUser(null);
      }
    });
  }, []);

  if (pending) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }

  return (
    <Context.Provider
      value={{
        currentUser,
        token,
        setToken,
        userdata,
        setUserData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
