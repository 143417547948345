import React, { useState, useEffect } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Alert,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useForm, ErrorMessage, Controller } from "react-hook-form";

import FieldError from "../components/FieldError";
import Label from "../components/Label";
import Spinner from "../components/Spinner";
import Title from "../components/Title";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";

import Footer from "../components/Footer";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { makeStyles } from "@mui/styles";
import { db, bucket } from "../util/config";

import Appbar from "../components/Appbar";

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid red",
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const years = [
  { label: "2015", value: "2015" },
  { label: "2016", value: "2016" },
  { label: "2017", value: "2017" },
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
];

export default function YearlyResults() {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const [alResults, setALresults] = useState([]);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [lastDoc, setLastDoc] = useState({});
  const [search, setSearch] = useState(null);

  const [olResults, setOLresults] = useState([]);
  const [lastDoc1, setLastDoc1] = useState({});
  const [search1, setSearch1] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    control: control2,
    formState: { errors: errors2, isValid: isValid2 },
    watch: watch2,
  } = useForm();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getALResults = async (d) => {
    setLoading(true);

    try {
      setSearch1(d.year.value);

      const data = await db
        .collection("alresults")
        .where("year.value", "==", d.year.value)
        .orderBy("result")
        .limit(10)
        .get();

      let alresultsList = [];

      //set last document
      let lastDocument = data.docs[data.docs.length - 1];

      setLastDoc1(lastDocument);

      data.docs.map((doc) => {
        alresultsList.push(doc.data());
      });

      setALresults(alresultsList);
      setLoading(false);
    } catch (err) {
      setALresults([]);
      setLoading(false);
    }
  };

  const fetchMoreALData = async () => {
    try {
      setLoading(true);

      let lastDocument = lastDoc1;

      if (!lastDoc1) {
        setError("No Results to load");
        throw new Error("No Results to load more");
      }

      setError(null);

      let nextDocument = db
        .collection("alresults")
        .where("year.value", "==", search1)
        .orderBy("result")
        .startAfter(lastDocument)
        .limit(10);

      nextDocument.get().then((querySnapshots) => {
        let alresultsList = [];

        //set last document
        if (!querySnapshots.empty) {
          lastDocument = querySnapshots.docs[querySnapshots.docs.length - 1];
          setLastDoc1(lastDocument);

          querySnapshots.docs.map((doc) => {
            return alresultsList.push(doc.data());
          });

          let allResults = alResults.concat(alresultsList);

          setALresults(allResults);
          setError(null);
        } else {
          setError("No Results to load");
        }
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getOLResults = async (d) => {
    setLoading(true);

    try {
      setSearch(d.year.value);

      const data = await db
        .collection("olresults")
        .where("year.value", "==", d.year.value)
        .orderBy("result")
        .limit(10)
        .get();

      let alresultsList = [];

      //set last document
      let lastDocument = data.docs[data.docs.length - 1];

      setLastDoc(lastDocument);

      data.docs.map((doc) => {
        alresultsList.push(doc.data());
      });

      setOLresults(alresultsList);
      setLoading(false);
    } catch (err) {
      setOLresults([]);
      setLoading(false);
    }
  };

  const fetchMoreOLData = async () => {
    try {
      setLoading(true);

      let lastDocument = lastDoc;

      if (!lastDoc) {
        setError("No Results to load");
        throw new Error("No Results to load more");
      }

      setError(null);

      let nextDocument = db
        .collection("olresults")
        .where("year.value", "==", search)
        .orderBy("result")
        .startAfter(lastDocument)
        .limit(10);

      nextDocument.get().then((querySnapshots) => {
        let alresultsList = [];

        //set last document
        if (!querySnapshots.empty) {
          lastDocument = querySnapshots.docs[querySnapshots.docs.length - 1];
          setLastDoc(lastDocument);

          querySnapshots.docs.map((doc) => {
            return alresultsList.push(doc.data());
          });

          let allResults = olResults.concat(alresultsList);

          setOLresults(allResults);
          setError(null);
        } else {
          setError("No Results to load");
        }
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Appbar />
      <Title name="Past Results" height="120px" />

      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="none"
          style={{
            marginTop: "12px",
            padding: "24px",
          }}
        >
          <Typography variant="h6" color="primary">
            <b>
              Check out the past results of brilliant students who aced their
              exams!
            </b>
          </Typography>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="OL - Results" {...a11yProps(0)} />
                <Tab label="AL - Results" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              {value === 0 ? (
                <>
                  <form
                    style={{ width: "100%", textAlign: "left" }}
                    onSubmit={handleSubmit2(getOLResults)}
                  >
                    <Grid item container md={6} xs={12} sm={12}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Label text="Year" marginBottom="0px" />
                      </Grid>

                      <Grid
                        item
                        md={6}
                        xs={12}
                        sm={12}
                        style={{ paddingRight: "24px" }}
                      >
                        <Controller
                          name="year"
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={years}
                              styles={errors2.classID ? customStyles : ""}
                            />
                          )}
                          control={control2}
                          rules={{ required: "Year is required" }}
                        />

                        <FieldError
                          text={errors2.year ? errors2.year.message : null}
                          marginTop="8px"
                        />
                      </Grid>

                      <Grid item md={6} xs={12} sm={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          <b>Search</b>
                        </Button>
                      </Grid>
                    </Grid>
                  </form>

                  <Grid container direction="row">
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      direction="row"
                      style={{ paddingTop: "48px" }}
                    >
                      {error ? (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sm={12}
                          style={{ marginBottom: "24px" }}
                        >
                          <Alert severity="error">
                            <b>{error}</b>
                          </Alert>
                        </Grid>
                      ) : null}

                      {success ? (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sm={12}
                          style={{ marginBottom: "24px" }}
                        >
                          <Alert severity="success">
                            <b>{success}</b>
                          </Alert>
                        </Grid>
                      ) : null}

                      {olResults.length > 0 ? (
                        <>
                          <Grid item md={12} xs={12} sm={12}>
                            <TableContainer
                              component={Paper}
                              style={{
                                boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
                              }}
                            >
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      <Typography variant="h6" color="initial">
                                        <b>Index</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Name</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Year</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>School</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>District</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Result</b>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {olResults.map((guide) => (
                                    <TableRow key={guide.olresultsID}>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.index}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.name}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.year?.value}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.school}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.district}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.result}</b>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>

                          <Grid
                            item
                            md={12}
                            xs={12}
                            sm={12}
                            style={{ marginTop: "24px" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={fetchMoreOLData}
                            >
                              <b>Fetch More</b>
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <Grid item md={12} xs={12} sm={12}>
                          <Typography variant="body1" color="initial">
                            No Results Available
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </TabPanel>

            <TabPanel value={value} index={1}>
              {value === 1 ? (
                <>
                  <form
                    style={{ width: "100%", textAlign: "left" }}
                    onSubmit={handleSubmit(getALResults)}
                  >
                    <Grid item container md={6} xs={12} sm={12}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Label text="Year" marginBottom="0px" />
                      </Grid>

                      <Grid
                        item
                        md={6}
                        xs={12}
                        sm={12}
                        style={{ paddingRight: "24px" }}
                      >
                        <Controller
                          name="year"
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={years}
                              styles={errors.year ? customStyles : ""}
                            />
                          )}
                          control={control}
                          rules={{ required: "Year is required" }}
                        />

                        <FieldError
                          text={errors.year ? errors.year.message : null}
                          marginTop="8px"
                        />
                      </Grid>

                      <Grid item md={6} xs={12} sm={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          <b>Search</b>
                        </Button>
                      </Grid>
                    </Grid>
                  </form>

                  <Grid container direction="row">
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      direction="row"
                      style={{ paddingTop: "48px" }}
                    >
                      {error ? (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sm={12}
                          style={{ marginBottom: "24px" }}
                        >
                          <Alert severity="error">
                            <b>{error}</b>
                          </Alert>
                        </Grid>
                      ) : null}

                      {success ? (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sm={12}
                          style={{ marginBottom: "24px" }}
                        >
                          <Alert severity="success">
                            <b>{success}</b>
                          </Alert>
                        </Grid>
                      ) : null}

                      {alResults.length > 0 ? (
                        <>
                          <Grid item md={12} xs={12} sm={12}>
                            <TableContainer
                              component={Paper}
                              style={{
                                boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
                              }}
                            >
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      <Typography variant="h6" color="initial">
                                        <b>Index</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Name</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Year</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>School</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>District</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>District Rank</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Result</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Z-Score</b>
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h6" color="initial">
                                        <b>Island Rank</b>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {alResults.map((guide) => (
                                    <TableRow key={guide.alresultsID}>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.index}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.name}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.year?.value}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.school}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.district}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.districtrank}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.result}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.zscore}</b>
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <b>{guide.islandrank}</b>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>

                          <Grid
                            item
                            md={12}
                            xs={12}
                            sm={12}
                            style={{ marginTop: "24px" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={fetchMoreALData}
                            >
                              <b>Fetch More</b>
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <Grid item md={12} xs={12} sm={12}>
                          <Typography variant="body1" color="initial">
                            No Results Available
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </TabPanel>
          </Box>
        </Container>
      )}
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
