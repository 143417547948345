import { Container, Grid, Typography, Button } from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";

import Appbar from "../components/Appbar";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";
import Title from "../components/Title";
import TestiminialsSlider from "../components/TestiminialsSlider";

import facebook from "../assets/images/icons8-facebook-480.svg";
import youtube from "../assets/images/icons8-youtube-480.svg";
import i3 from "../assets/images/i2.png";

import h1 from "../assets/images/h1.jpg";
import h2 from "../assets/images/h2.jpg";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  outerCard: {
    padding: "24px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      marginBottom: "24px",
    },
  },
  img: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "150px",
    },
  },
  ctr: {
    padding: "24px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
  imgMb: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "24px",
    },
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <>
      <Appbar />

      <Gallery />

      {new Date().getTime() < new Date("2023-12-22").getTime() && (
        <Container
          maxWidth="none"
          style={{
            backgroundColor: "#E6E6F2",
            paddingTop: "12px",
          }}
        >
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                className={classes.ctr}
                style={{
                  borderRadius: "4px",
                }}
              >
                <p>
                  <b>
                    9 ශ්‍රේණිය ඔබට 10 ශ්‍රේණියට ඇතුළත් වීම සදහා කෙරෙන පරීක්ෂණය
                  </b>
                </p>
                <Button
                  onClick={() =>
                    window.open("https://forms.gle/ZNiDEXL3KmEt1yVVA", "_blank")
                  }
                  variant="contained"
                  size="large"
                >
                  Click Here
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}

      <Container
        maxWidth="none"
        style={{
          backgroundColor: "#E6E6F2",
          // paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Container
          maxWidth="lg"
          style={{
            padding: "24px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              className={classes.ctr}
              style={{
                borderRadius: "4px",
              }}
            >
              <img
                src={h1}
                alt="h1"
                className={classes.imgMb}
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              className={classes.ctr}
              style={{
                borderRadius: "4px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h4"
                color="initial"
                style={{ marginBottom: "24px" }}
                align="justify"
              >
                <b>
                  Are you looking for the best information technology class in
                  Sri Lanka that could help you secure the best IT results of
                  your academic career?
                </b>
              </Typography>

              <Typography
                variant="h6"
                color="initial"
                style={{ marginBottom: "24px" }}
                align="justify"
              >
                <b>
                  Or are you on the hunt for an Information Technology class
                  that will equip you with the right skillset and additional
                  support to pursue a career in IT after graduating?
                  <br />
                  <br />
                  Whatever your objective may be, we will support you to reach
                  them with no difficulties, guiding you through topics you may
                  find hard and empowering you to explore your passion for
                  information technology further.
                  <br />
                  <br />
                  As one of the leading Information Technology classes in Sri
                  Lanka, we are committed to our unique approach of delivering a
                  personalized learning experience to each student.
                  <br />
                  <br />
                  Join us today and see the difference for yourself!
                </b>
              </Typography>

              <NavLink to="/login">
                <Button
                  variant="contained"
                  size="large"
                  style={{ marginRight: "12px" }}
                >
                  <b>Login</b>
                </Button>
              </NavLink>
              <NavLink to="/register">
                <Button variant="contained" size="large">
                  <b>Register</b>
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth="none"
        style={{ paddingTop: "48px", paddingBottom: "48px" }}
      >
        <Container
          maxWidth="lg"
          style={{
            padding: "24px",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              className={classes.ctr}
              style={{
                borderRadius: "4px",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h4"
                color="initial"
                style={{ marginBottom: "24px" }}
              >
                <b>Browse Our Free Study Guides</b>
              </Typography>

        

              <NavLink to="/ol-guides">
                <Button
                  variant="contained"
                  size="large"
                  style={{ marginRight: "12px" }}
                >
                  <b>OL Guides</b>
                </Button>
              </NavLink>

              <NavLink to="/al-guides">
                <Button variant="contained" size="large">
                  <b>AL Guides</b>
                </Button>
              </NavLink>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{
                borderRadius: "4px",
                padding: "24px",
              }}
            >
              <img src={h2} alt="h1" style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth="none"
        style={{
          backgroundColor: "#E6E6F2",
          paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Container maxWidth="lg" className={classes.ctr}>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{ padding: "24px", textAlign: "left" }}
            >
              <Typography
                variant="h5"
                color="initial"
                style={{ marginBottom: "24px" }}
              >
                <b>Classes for O/L and A/L Studients</b>
              </Typography>

              <Typography variant="h6" color="initial">
                Ace your O/L and A/L IT exams with the structured learning
                materials that we provide, supported by decades of experience in
                the education sphere.
              </Typography>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{ padding: "24px", textAlign: "left" }}
            >
              <Typography
                variant="h5"
                color="initial"
                style={{ marginBottom: "24px" }}
              >
                <b>Diverse learning material</b>
              </Typography>

              <Typography variant="h6" color="initial">
                Learn for the future with our diverse learning materials,
                covering the curriculum and more! Prepare for the career in IT
                that you may someday take on after acing your exams.
              </Typography>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{ padding: "24px", textAlign: "left" }}
            >
              <Typography
                variant="h5"
                color="initial"
                style={{ marginBottom: "24px" }}
              >
                <b>Interactive Sessions</b>
              </Typography>

              <Typography variant="h6" color="initial">
                We firmly believe that lessons are at their best when students
                and the teacher can interact and engage with each other. That is
                why we encourage interactive learning sessions.
              </Typography>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              style={{ padding: "24px", textAlign: "left" }}
            >
              <Typography
                variant="h5"
                color="initial"
                style={{ marginBottom: "24px" }}
              >
                <b>Experienced Resource Personal</b>
              </Typography>

              <Typography variant="h6" color="initial">
                Enriched by experience that spans decades, we are equipped with
                the knowledge, skills and understanding to provide a world-class
                IT education.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth="none"
        style={{
          paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Container maxWidth="lg" className={classes.ctr}>
          <Grid container direction="row" alignItems="center">
            <Grid item md={12} xs={12} sm={12}>
              <Typography variant="h4" color="initial">
                <b>What our students say</b>
              </Typography>

              <TestiminialsSlider />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth="none"
        style={{
          backgroundColor: "#E6E6F2",
          paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Container maxWidth="lg" className={classes.ctr}>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              style={{ padding: "24px", textAlign: "center" }}
            >
              <Typography
                variant="h4"
                color="initial"
                style={{ marginBottom: "24px" }}
              >
                <b>Join US</b>
              </Typography>
              <a href="https://www.facebook.com/jeevanthas">
                <img src={facebook} style={{ width: "96px" }} alt="Facebook" />
              </a>

              <a href="https://www.youtube.com/channel/UCMTnhDX_Xr54GdOo4xzUfbg?app=desktop">
                <img src={youtube} style={{ width: "96px" }} alt="youtube" />
              </a>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
