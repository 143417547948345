import { Container, Grid, Typography } from "@mui/material";
import React from "react";

import { makeStyles } from "@mui/styles";

import Appbar from "../components/Appbar";
import Footer from "../components/Footer";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Divider from "@mui/material/Divider";
import j from "../assets/images/j.png";
import Title from "../components/Title";

const useStyles = makeStyles((theme) => ({
  outerCard: {
    padding: "24px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      marginBottom: "24px",
    },
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <>
      <Appbar />
      <Title name="About us" height="120px" />

      <Container
        maxWidth="lg"
        style={{
          marginBottom: "50px",
          padding: "24px",
        }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "24px",
            }}
          >
            <Typography variant="h6" color="primary">
              <b>
                As one of the top IT tuition classes in Sri Lanka, we are
                dedicated to delivering the best learning experience to all our
                students. We aspire to challenge the standard IT tuition class
                setups and deliver a special and personalized experience that
                students could benefit from.
                <br />
                <br />
                Being an IT tuition class with a passion to always thrive for
                the highest peaks, our goal is to prepare students for excellent
                results and ignite a passion for Information Technology. We
                believe a strong foundation laid at this level could assist
                students to perform faster and successfully when they are in a
                professional environment. Hence, we take pride in building the
                next generation of tech innovators of Sri Lanka.
                <br />
                <br />
                Come and join us to excel at your A/L and O/L examinations and
                go on to take on the world of Information Technology.
              </b>
            </Typography>

            <Divider style={{ marginTop: "24px" }} />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            style={{
              padding: "24px",
            }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ marginBottom: "24px" }}
            >
              <b>● Vision</b>
            </Typography>

            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: "24px" }}
            >
              <b>
                To provide an exceptional learning experience for all students,
                enabling them to outperform themselves and seek excellence in
                results in the domain of Information Technology.
              </b>
            </Typography>

            <Divider />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            style={{
              padding: "24px",
            }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ marginBottom: "24px" }}
            >
              <b>● Mission </b>
            </Typography>

            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: "24px" }}
            >
              <b>
                To illuminate the minds of the next generation of technological
                innovators and plant the seed of curiosity through practical and
                theoretical wisdom that goes beyond the traditional scope of
                tuition.
              </b>
            </Typography>

            <Divider />
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "24px",
            }}
          >
            <Typography variant="h4" color="initial">
              <b>Mr. Jeevantha Hewa Pathiranatha </b>
            </Typography>
            <Typography variant="h6" color="initial">
              <b>Masters in IT (University of Colombo),</b>
            </Typography>
            <Typography variant="h6" color="initial">
              <b>B.Sc (Hon's) Special in IT,</b>
            </Typography>
            <Typography variant="h6" color="initial">
              <b>PGDE, Marking Examiner,</b>
            </Typography>
            <Typography variant="h6" color="initial">
              <b>Government School Teacher</b>
            </Typography>
            <Typography
              variant="h5"
              color="initial"
              style={{
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <Divider />
            </Typography>

            <Typography variant="h5" color="initial">
              <b>VOICE : +94 77 720 8864</b>
            </Typography>
            <Typography variant="h5" color="initial">
              <b>E-MAIL : sjeevantha@gmail.com</b>
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "24px",
            }}
          >
            <img src={j} alt="sir" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
