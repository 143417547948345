import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../assets/images/img1.png";
import img2 from "../assets/images/img2.png";
import img3 from "../assets/images/img3.png";
import img4 from "../assets/images/img4.png";
import img5 from "../assets/images/img5.png";
import img6 from "../assets/images/img6.png";
import img7 from "../assets/images/img7.png";

import m1 from "../assets/images/m1.png";
import m2 from "../assets/images/m2.png";
import m3 from "../assets/images/m3.png";
import m4 from "../assets/images/m4.png";
import m5 from "../assets/images/m5.png";
import m7 from "../assets/images/m7.png";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function Gallery() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slides",
    autoplaySpeed: 5000,
    autoplay: true,
    cssEase: "linear",
  };

  return (
    <div style={{ width: "100vw", display: "flex", flexDirection: "column" }}>
      {isMobile ? (
        <Slider {...settings}>
          <div>
            <img
              src={m7}
              alt="m1"
              style={{ position: "relative", width: "100vw" }}
            />

            <div
              style={{
                position: "absolute",
                top: "calc(50% - 30px)",
                left: "calc(150vw - 170px)",
              }}
            >
              <NavLink to="/ol-guides">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "5px",
                    padding: "16px",
                    backgroundColor: "white",
                    color: "#000080",
                    fontSize: "16px",
                  }}
                >
                  <b>OL Guides</b>
                </Button>
              </NavLink>
              <NavLink to="/al-guides">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    padding: "16px",
                    backgroundColor: "white",
                    color: "#000080",
                    fontSize: "16px",
                  }}
                >
                  <b>AL Guides</b>
                </Button>
              </NavLink>
              <NavLink to="/blogs">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "5px",
                    padding: "16px",
                    backgroundColor: "white",
                    color: "#000080",
                    fontSize: "16px",
                  }}
                >
                  <b>Blogs</b>
                </Button>
              </NavLink>
            </div>
          </div>

          <div>
            <img style={{ width: "100vw" }} src={m1} alt="m1" />
          </div>

          <div>
            <img style={{ width: "100vw" }} src={m2} alt="m2" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={m3} alt="m3" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={m4} alt="m4" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={m5} alt="m5" />
          </div>
        </Slider>
      ) : (
        <Slider {...settings}>
          <div>
            <img
              src={img7}
              alt="m1"
              style={{ position: "relative", width: "100vw" }}
            />

            <div
              style={{
                position: "absolute",
                top: "calc(50% - 80px)",
                left: "calc(150vw - 361px)",
              }}
            >
              {" "}
              <NavLink to="/ol-guides">
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    padding: "32px",
                    backgroundColor: "white",
                    color: "#000080",
                    fontSize: "32px",
                  }}
                >
                  <b>OL Guides</b>
                </Button>
              </NavLink>
              <NavLink to="/al-guides">
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    marginLeft: "20px",
                    padding: "32px",
                    backgroundColor: "white",
                    color: "#000080",
                    fontSize: "32px",
                  }}
                >
                  <b>AL Guides</b>
                </Button>
              </NavLink>
              <NavLink to="/blogs">
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "20px",
                    padding: "32px",
                    backgroundColor: "white",
                    color: "#000080",
                    fontSize: "32px",
                  }}
                >
                  <b>Blogs</b>
                </Button>
              </NavLink>
            </div>
          </div>
          <div>
            <img style={{ width: "100vw" }} src={img1} alt="img2" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={img2} alt="img2" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={img3} alt="img3" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={img6} alt="img4" />
          </div>
          <div>
            <img style={{ width: "100vw" }} src={img5} alt="img5" />
          </div>
        </Slider>
      )}
    </div>
  );
}
