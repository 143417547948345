import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import SchoolIcon from "@mui/icons-material/School";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { ListItemIcon, ListItemText, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#ffffff",
      color: "#000080",
    },
  },
}));

export default function BasicMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuItem>
        <ListItemIcon>
          <SchoolIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>
          <Button
            sx={{
              my: 2,
              display: "block",
              margin: 0,
              padding: 0,
              textTransform: "none",
            }}
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Typography textAlign="left" style={{ color: "#000000" }}>
              <b>Classes</b>
            </Typography>
          </Button>

          <Menu
            className={classes.menu}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              <AccessTimeIcon
                fontSize="small"
                style={{ marginBottom: "3px", marginRight: "6px" }}
              />
              <NavLink
                to="/schedules"
                style={{ color: "#000000", marginRight: "8px" }}
              >
                <b>Schedule</b>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LocationOnIcon
                fontSize="small"
                style={{ marginBottom: "3px", marginRight: "6px" }}
              />
              <NavLink
                to="/location"
                style={{ color: "#000000", marginRight: "8px" }}
              >
                <b>Location</b>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <PlagiarismIcon
                fontSize="small"
                style={{ marginBottom: "3px", marginRight: "6px" }}
              />
              <NavLink
                to="/exam-results"
                style={{ color: "#000000", marginRight: "8px" }}
              >
                <b>Results</b>
              </NavLink>
            </MenuItem>
          </Menu>
        </ListItemText>
      </MenuItem>
    </div>
  );
}
