import React from "react";

import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import Provider from "./util/Provider";
import AuthRoute from "./util/AuthRoute";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import AllClasses from "./pages/AllClasses";
import MyClasses from "./pages/MyClasses";
import Modules from "./pages/Modules";
import Results from "./pages/Results";
import Blogs from "./pages/Blogs";
import SingleBlog from "./pages/SingleBlog";
import YearlyResults from "./pages/YearlyResults";
import OLGuides from "./pages/OLGuides";
import ALGuides from "./pages/ALGuides";
import Location from "./pages/Location";
import Schedules from "./pages/Schedules";
import Gallery from "./pages/Gallery";
import Profile from "./pages/Profile";
import About from "./pages/About";

const theme1 = createTheme({
  typography: {
    h1: {
      fontFamily: "AirbnbCerealMedium",
    },
    h2: {
      fontFamily: "AirbnbCerealMedium",
    },
    h3: {
      fontFamily: "AirbnbCerealMedium",
    },
    h4: {
      fontFamily: "AirbnbCerealMedium",
    },
    h5: {
      fontFamily: "AirbnbCerealMedium",
    },
    h6: {
      fontFamily: "AirbnbCerealBook",
    },
    body1: {
      fontFamily: "AirbnbCerealBook",
    },
    body2: {
      fontFamily: "AirbnbCerealBook",
    },
    p: {
      fontFamily: "AirbnbCerealBook",
    },
    overline: {
      fontFamily: "AirbnbCerealMedium",
    },
    button: { fontFamily: "AirbnbCerealBook" },
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#B2BEB5",
    },
  },
});

let theme = responsiveFontSizes(theme1);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider>
          <BrowserRouter>
            <Switch>
              {/* 
              <Route exact path="/" component={Landing} /> */}
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/blog/:id" component={SingleBlog} />
              <Route exact path="/" component={Home} />
              <Route path="/exam-results" component={YearlyResults} />
              <Route path="/ol-guides" component={OLGuides} />
              <Route path="/al-guides" component={ALGuides} />
              <Route path="/location" component={Location} />
              <Route path="/schedules" component={Schedules} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/about" component={About} />

              <AuthRoute path="/all-classes" component={AllClasses} />
              <AuthRoute path="/my-classes" component={MyClasses} />
              <AuthRoute path="/results" component={Results} />
              <AuthRoute path="/modules/:id" component={Modules} />
              <AuthRoute path="/profile" component={Profile} />
              {/* <Route component={NotFound} /> */}
            </Switch>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
