import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Alert from "@mui/lab/Alert";

import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";

import { db } from "../util/config";
import { Context } from "../util/Provider";

import Appbar from "../components/Appbar";
import Spinner from "../components/Spinner";
import ConfirmBox from "../components/ConfirmBox";
import Snack from "../components/Snack";
import Title from "../components/Title";

export default function MyClasses() {
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [myClasses, setMyClasses] = useState([]);

  const { currentUser } = useContext(Context);

  const getClasses = async () => {
    try {
      const myClasses = await db
        .collection("enrolled-students")
        .where("studentID", "==", currentUser.studentID)
        .get();

      let myClassesList = [];

      myClasses.docs.map((doc) => {
        myClassesList.push(doc.data());
      });

      setMyClasses(myClassesList);
    } catch (e) {
      setMyClasses([]);
    }
  };

  const handleClick = async (classID) => {
    try {
      setLoading(true);

      let enrollmentID = uuidv4();

      const enroll = await db
        .collection("enrollments")
        .doc(enrollmentID)
        .set({
          enrollmentID,
          studentID: currentUser.studentID,
          mobile: currentUser.mobile,
          name: currentUser.name,
          classID,
          enrolled: false,
          createdAt: moment(new Date()).format("YYYY-MM-DD"),
        });

      setSuccess(`Enrollment request for ${classID} sent succesfully`);
      setError(null);

      setLoading(false);
    } catch (e) {
      setSuccess(null);
      setError("something went wrong, please try again");
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const ad = await getClasses();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Appbar />
      <Title name="My Classes" height="120px" />
      {loading ? (
        <Grid container component="main" spacing={3}>
          <Grid
            style={{
              display: "flex",
              direction: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
            }}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Spinner type="pulse" />
          </Grid>
        </Grid>
      ) : (
        <Container
          maxWidth="md"
          style={{
            marginTop: "12px",
            padding: "24px",
            borderRadius: "8px",
          }}
        >
          {myClasses.length > 0 ? (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{
                  textAlign: "left",
                }}
              >
                {error ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="error">
                      <b>{error}</b>
                    </Alert>
                  </Grid>
                ) : null}

                {success ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ marginBottom: "24px" }}
                  >
                    <Alert severity="success">
                      <b>{success}</b>
                    </Alert>
                  </Grid>
                ) : null}

                <Grid item md={12} xs={12} sm={12}>
                  <TableContainer
                    component={Paper}
                    style={{
                      boxShadow: `0px 10px 38px rgba(221, 230, 237, 1)`,
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <b>Class Name</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Year</b>
                          </TableCell>

                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {myClasses.map((myClass) => (
                          <TableRow key={myClass.classID}>
                            <TableCell align="left">
                              {myClass.className}
                            </TableCell>
                            <TableCell align="left">
                              {myClass.classYear}
                            </TableCell>

                            <TableCell align="right">
                              <NavLink to={`/modules/${myClass.classID}`}>
                                <Button variant="contained" color="primary">
                                  View
                                </Button>
                              </NavLink>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item md={12} xs={12} sm={12}>
              <Typography variant="body1" color="initial">
                No classes available
              </Typography>
            </Grid>
          )}
        </Container>
      )}
      <Title name="" height="30px" />
      <Footer />
    </>
  );
}
