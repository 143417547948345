import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SchoolIcon from "@mui/icons-material/School";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";

import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#000080",
      color: "white",
    },
  },
}));

export default function BasicMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ marginRight: "8px" }}>
      <Button
        sx={{ my: 2, color: "white", display: "block" }}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <SchoolIcon
          fontSize="small"
          style={{ marginBottom: "-5px", marginRight: "6px" }}
        />
        <b>Classes</b>
      </Button>

      <Menu
        className={classes.menu}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <AccessTimeIcon
            fontSize="small"
            style={{ marginBottom: "3px", marginRight: "6px" }}
          />
          <NavLink
            to="/schedules"
            style={{ color: "#ffffff", marginRight: "8px" }}
          >
            <b>Schedule</b>
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LocationOnIcon
            fontSize="small"
            style={{ marginBottom: "3px", marginRight: "6px" }}
          />
          <NavLink
            to="/location"
            style={{ color: "#ffffff", marginRight: "8px" }}
          >
            <b>Location</b>
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <PlagiarismIcon
            fontSize="small"
            style={{ marginBottom: "3px", marginRight: "6px" }}
          />
          <NavLink
            to="/exam-results"
            style={{ color: "#ffffff", marginRight: "8px" }}
          >
            <b>Results</b>
          </NavLink>
        </MenuItem>
      </Menu>
    </div>
  );
}
